.home_tab .nav-tabs>li>button.active {
    border: 2px solid rgb(63 39 131);
    z-index: 1;
    color: #3f2783;
    font-weight: 700;
}
.home_tab .nav-tabs .nav-link:focus, .home_tab .nav-tabs .nav-link:hover {
    border-color:  rgba(63,39,131,.7);
    border: 2px solid rgba(63,39,131,.7);

}
button#case-list-tabs-tab-shared {
    color: #3f2783;
}
#case-list-tabs-tabpane-shared.active.show {
    color: #3f2783;
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}
button#case-list-tabs-tab-summary {
    color: #3f2783;
}
button#case-list-tabs-tab-completed {
    color: #1DCE00;
}
div#case-list-tabs-tabpane-completed {
    color: #1DCE00;
    border: 2px solid #1DCE00;
    padding: 15px 0;
    position: relative;
}
#case-list-tabs-tab-completed.active{
    border: 2px solid #1DCE00;
}
button#case-list-tabs-tab-pending {
    color: #FF7800;
}
div#case-list-tabs-tabpane-pending{
    color: #FF7800;
    border: 2px solid #FF7800;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tab-pending.active{
    border: 2px solid #FF7800;
}
button#case-list-tabs-tab-expired{
    color: #F00;
}
div#case-list-tabs-tabpane-expired{
    color: #F00;
    border: 2px solid #F00;
    padding: 15px 0;
    position: relative;
}
#case-list-tabs-tab-expired.active{
    border: 2px solid #F00;
}
button#case-list-tabs-tab-incomplete{
    color: #006DFF;
}
div#case-list-tabs-tabpane-incomplete{
    color: #006DFF;
    border: 2px solid #006DFF;
    padding: 15px 0;
    position: relative;
}
#case-list-tabs-tab-incomplete.active{
    border: 2px solid #006DFF;

}
.home_tab .nav-tabs>li>button {
    background-color: #fff;
    border: 2px solid rgba(63,39,131,.7);
    border-bottom: none!important;
    border-radius: 20px 20px 0 0;
    cursor: default;
    display: block;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    line-height: 24px;
    margin-right: 15px;
    padding: 10px 30px;
    position: relative;
}   
.last-row-class>td>span::before {
    background: rgba(63,39,131,.7);;
    bottom: 0;
    content: "";
    min-height: 0px;
    left: 0.39rem;
    position: absolute;
    /* width: 4px; */
    z-index: 1;
    top: 0px;
}
.circle_gap{
    display: inline-flex;
    width: 31px;
    height: 29px;
    padding: 5px 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
    border-radius: 20px;
    border: 1px solid var(--Primary-Purple, #3F2783);
}
#case-list-tabs-tab-completed .circle_gap {
    border: 1px solid #1DCE00;
}
#case-list-tabs-tab-pending .circle_gap {
    border: 1px solid #FF7800;
}
#case-list-tabs-tab-expired .circle_gap {
    border: 1px solid #F00;
}
#case-list-tabs-tab-incomplete .circle_gap {
    border: 1px solid #006DFF;
}
img.check {
    background-color: #3f2783;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    padding: 3px;
  
}
.icon_border{
    display: flex;
    border-radius: 20px;
    border: 1px solid;
    width: 145px;
    height: 30px;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
}
.pending{
    color: #FF7800;
}
.incomplete{
    color: #006DFF;
}
.expired{
    color: #F00;
}
.share {
    color: #1DCE00;
}
.expired_dis{
color: #3F278380 !important;
}
.circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #3f2783;
    display: block;
    margin-left: 13px;
    margin-right: 13px;
    position: relative;
}
.circle::before {
    background: rgba(63, 39, 131, .7);
    bottom: 0;
    content: "";
    min-height: 90px;
    left: 0.39rem;
    position: absolute;
    width: 1px;
    z-index: 1;
    top: 0;
}
.font_30{
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    align-self: center;
    
}
.scoring_status_box{
    width:125px;
    padding: 10px 0px 0px 0px;
    border: 5px solid #ececec;
    border-radius: 10px;
    
}
.status_title{
    font-size: 14px;
    font-weight: 700;
}
.scorefoot__btn{
    width: 48%;
}
